import Vue from 'vue';
import VueI18n from 'vue-i18n';
import Cookies from 'js-cookie'

import zh from './zh';
import en from './en';

Vue.use(VueI18n);

const messages = {
  zh: {
    ...zh
  },
  en: {
    ...en
  }
}

export function getLanguage () {
  const chooseLanguage = Cookies.get('cecc-lang');
  if (chooseLanguage) return chooseLanguage;
  else return 'zh';
}

const i18n = new VueI18n({
  locale: getLanguage(),
  messages
})

export default i18n;