
let IDC = {
  signUp: 'Sign Up',
  readMore: 'READ MORE',
  rules: 'Rules',
  // IDC_title1: '2021',
  IDC_title1: 'Second',
  IDC_title2: 'The Theme of IDC2021: Green Life , City IP',
  IDC_second1: '',
  IDC_second1_1: 'Contribute to the development of the YRD .',
  IDC_second2: '',
  IDC_second2_1: 'Encourage and support international collaboration in innovation and creativity .',
  IDC_second3: '',
  IDC_second3_1: 'Promote green technology to underpin a sustainable future and contribute to designing a green world .',
  IDC_second4: '',
  IDC_second4_1: 'Give an opportunity for existing and new businesses to showcase their ideas, connect with potential partners and attract investment .',
  IDC_main: 'Organizer: YRDCECC',
  IDC_main1: 'Guided by: Regional Cooperation Office of the YRD Council',
  IDC_main2: 'Co-Sponsors: Shanghai Wuwei Creative Economy Regional Cooperation Promotion Center',
  IDC_main2_1: 'Shanghai Creative Industry Association',
  IDC_main3: 'Partners',
  IDC_main4: '41 Membership Cities of YRD CECC',
  IDC_main5: 'Glasgow Chamber of Commerce',
  IDC_main6: 'Creativity World Forum of Districts of Creativity Network (DC Network）',
  IDC_main7: 'C2 (Commerce + Creativity) Top Companies of Montreal ',
  IDC_main8: 'Paris Creative Valley',
  IDC_main9: 'Design Incubators of Deutso University of Spain',
  IDC_main10: 'Donghua-Edinburgh Center for Creative Industry',
  IDC_main11: 'Skylab of Technical University of Denmark ',
  IDC_main12: 'World Textile University Alliance',
  IDC_third1: 'A UNIQUE INTERNATIONAL PROGRAMME ON CREATIVITY AND INNOVATION',
  IDC_third2: '',
  IDC_third3_1: 'Who can apply?',
  IDC_third3_2: 'Students',
  IDC_third3_3: 'Young Entrepreneurs',
  IDC_third3_4: 'Small and Medium Enterprises',
  IDC_third3_5: 'New Idea Development  or Launch Plan from successful business and companies',
  IDC_third3_6: 'Not-for-Profit organizations',
  IDC_third3_7: '',
  IDC_third4_1: 'Why you should attend',
  IDC_third4_2: 'gain project investment funds',
  IDC_third4_3: 'join CECC IDC network and receive regular business support from CECC and IDC partners',
  IDC_third4_4: 'set-up business in the Creative Industry Park in Nanxiang Shineland Park in Shanghai with extremely favourable financial and taxation arrangements',
  IDC_third4_5: 'workwith T-mall of Alibaba &Top 10 Taobao partner',
  IDC_third4_6: 'operating and marketing companies in China',
  IDC_third5_1: 'Three THEMES:',
  IDC_third5_2: 'Culture and Creative Design',
  IDC_third5_3: 'Industry Design （e.g. product design, package design & visual design）',
  IDC_third5_4: 'Fashion  Design',
  IDC_third5_5: 'Innovative Design(e.g. digital products, new materials)',
  IDC_forth1_1: 'Arts and Design',
  IDC_forth1_2: 'Innovation and Creativity',
  IDC_forth1_3: 'E-Business with T-mall and other major e-commerce platforms',
  IDC_forth2_1: 'Proposals are invited in areas including: ',
  IDC_forth2_2: '',
  IDC_forth2_3: 'Culture and Creative Design	',
  IDC_forth2_4: 'Industry Design e.g. product design, package design, visual design and Environment design',
  IDC_forth2_5: 'Fashion  Design',
  IDC_forth2_6: 'Innovative Design(e.g. digital products, new materials) ',
  IDC_forth3_1: 'This theme is jointly held with Shanghai Nanxiang Shineland Creative Park.',
  IDC_forth3_2: '',
  IDC_forth3_3: '',
  IDC_forth3_4: 'The winner of this theme will be offered a place in the Park and a number of supportive policies, including taxation benefits and business launch fund.',
  IDC_forth3_5: 'Proposals are invited in areas including:',
  IDC_forth3_6: 'Environmental technology and products technology',
  IDC_forth3_7: 'Health products',
  IDC_forth3_8: 'Artificial Intelligence Products',
  IDC_forth3_9: 'Creative Design ',
  IDC_forth4_1: 'The winning projects in this theme will obtain a “green channel” to open an e-shop on T-mall and other major e-commerce platforms and receive support from the Top 10 Taobao partner operation and marketing companies.',
  IDC_forth4_2: '',
  IDC_forth4_3: '',
  IDC_forth4_4: 'Proposals are invited in areas including:',
  IDC_forth4_5: 'Culture promotion products',
  IDC_forth4_6: 'Beauty products',
  IDC_forth4_7: 'Food and drink',
  IDC_forth4_8: 'Others',
  IDC_forth5_1: 'As part of the continuing development of the YRD, the CECC is launching an annual International Design Competition (IDC), the inaugural one of which is planned to be held in Shanghai 25-29 November. The IDC will:',
  IDC_forth5_2: 'Contribute to the development of the YRD ',
  IDC_forth5_3: 'Encourage and support international collaboration in innovation and creativity',
  IDC_forth5_4: 'Promote green technology to underpin a sustainable future and contribute to designing a green world',
  IDC_forth5_5: 'Give an opportunity for existing and new businesses to showcase their ideas, connect with potential partners and attract investment.',
}
let User = {
  welcome: 'Welcome to CECC, please login',
  enterEmail: 'Please input your Email',
  enterPassword: 'Please input your password',
  enterCode: 'Please input your security code',
  confirmPassword: 'Please confirm your password',
  forgetPassword: 'Forget Password',
  createAnAccount: 'Create an Account',
  createAccount: 'Create an Account',
  resetPassword: 'Reset Password',
  reset: 'Reset',
  login: 'Login ',
  register: 'Register',
  logInWithAnotherAccount: 'Log in with another account',
  whereToKnow: 'How do you know CECC?（Multiple Choice）',
  where1: ' I am a past participant',
  where2: 'Media publicity',
  where3: 'Exhibition',
  where4: 'Publicity',
  where5: 'Others-introduction',
  where6: 'Others',
  tips: 'Notice',
  tips1: 'You have not yet finished your personal information.',
  tips2: 'Please read the IDC registration clause!',
  confirm: 'Confirm',
  notice: 'Notice',
  account: 'Account',
  entryList: 'Application',
  certificate: 'Certificate',
  moreT: 'IDC Rules',
  moreJ: 'Jiang Nan',
  registrationInfo: 'Registration Info',
  moreC: 'Demand',
  accountName: 'Account name',
  changePassword: 'Change Password',
  help: 'Help',
  logout: 'Logout',
  name: 'Name',
  submitterInfo: 'Submitter Info',
  firstName: 'Please fill in first Name',
  lastName: 'Please fill in last Name',
  identity: 'Identity',
  identitySelect: 'Please select your identity',
  unitName: 'Unit Name',
  enterUnitName: 'Please fill in your unit name',
  contactInfo: 'Contact Info',
  mobilePhone: 'Mobile Phone',
  contactPhone: 'Contact Phone',
  Wechat: 'Wechat',
  enterTelPhone: 'Please input your Telephone number',
  enterTelWechat: 'Please input your Wechat',
  enterContactPhone: 'Please input your Telephone number',
  Email: 'Email',
  fixedLineTelephone: 'fixed-line telephone',
  enterFixedLineTelephone: 'Please input your fixed-line Telephone',
  Region: 'Country / Region',
  selectRegion: 'Please select the country / region',
  Ihaveread: 'I have read and accepted the IDC Agreement',
  NewsletterSubscription: 'Newsletter Subscription',
  saveInformation: 'Save Information',
  modifyInformation: 'Modify Info',
  Enterprise: 'Enterprise',
  Institution: 'Institution(student or teacher)',
  SocialIndividuals: 'Social individuals or organizations',
  designCompany: 'Design company',
  Other: 'Other',
  teacher: 'Teacher',
  student: 'Student',
  chooseEductionLevel: 'Please choose your eduction level',
  Education: 'Education',
  AcademicTitle: 'Academic title/Occupation',
  enterOther: 'Please fill in your identity',
  occupation: 'occupation',
  fillInYourAc: 'Please fill in your academic title/occupation',
  fillInYourOc: 'Please fill in your occupation',
  Doctorate: 'Doctorate',
  Master: 'Master',
  Bachelor: 'Bachelor',
  GED: 'GED',
  InstitutionName: 'Institution Name',
  enterInstitutionName: 'Please input your Institution Name',
  employeeNumber: 'Employee Number',
  chooseNumberOfEmployees: 'Please choose number of employees',
  P1: 'Name of person or organization',
  P2: 'Please enter the name of the person or organization',
  P3: 'Company name',
  P4: 'Please enter the company name',
  P5: 'Design direction',
  P6: 'Please select a design direction',
  P7: 'Please enter the original password',
  P8: 'Please enter a 4-8 digit English or numeric password',
  P9: 'Please enter a new password again',
  P10: 'Submit',
  P11: 'Account information is not yet complete, please check the "-" part of the information.',
  P12: 'Please enter your password',
  P13: 'The password does not match twice',
  P14: 'Modified successfully',
  P15: 'Modified successfully',
  P16: 'You have registered for other modules',
  P17: 'Click to refresh the captcha',
  P18: 'Please input the captcha',
  P19: 'Document Download',
  // P20: '2021 IDC Guidelines',
  // P21: '2021 IDC Poster',
  P20: 'Second IDC Guidelines',
  P21: 'Second IDC Poster',
  // P22: '2020 IDC Registration Process',
  P22: 'Second IDC Registration Process',
  D1: '城市文化创意产品设计',
  D2: '旅游纪念品设计',
  D3: '城市特色产品设计（含食品、农产品等）',
  D4: '区域品牌设计',
  D5: '城市形象相关设计',
  D6: '其他',
  D7: '流行服饰',
  D8: '母婴用品',
  D9: '时尚穿戴产品设计',
  D10: '时尚产品创新设计',
  D11: '时尚周边产品设计',
  D12: '其他',
  D13: '电子数码产品',
  D14: '厨房卫浴家电',
  D15: '设备设施器具',
  D16: '化妆品品牌设计',
  D17: '特色产业产品包装设计',
  D18: '生活杂项用品',
  D19: '其他',
  D20: '城市特色主题数字创意设计',
  D21: '特色产业的新方向设计',
  D22: '新材料应用设计',
  D23: '技术创新应用',
  D24: '模式创新应用',
  D25: '其他',
  D26: '非遗文化',
  D27: '艺术展览',
  D28: '电子竞技',
  D29: '创意设计',
  D30: '其他',
  D31: '电子科技',
  D32: '智慧产品开发及应用',
  D33: '人工智能',
  D34: '智能材料',
  D35: '智慧汽车',
  D36: '无人机运用',
  D37: '环保节能产业',
  D38: '其他',
  D39: '生物科技',
  D40: '医疗器械',
  D41: '基因测序',
  D42: '实验平台',
  D43: '靶向治疗',
  D44: 'Meeting up the talent scouts',
  D45: '',
  D46: 'More Scouts',
  D47: 'The account with this keycode was not found',
  D48: 'E-mail format is incorrect'
}
let details = {
  goal: 'Goal',
  g1: 'As part of the continuing development of the YRD, the CECC is launching an annual International Design Competition (IDC), the inaugural one of which is planned to be held in Shanghai 25-29 November. The IDC will:',
  g2: 'Contribute to the development of the YRD',
  g3: 'Encourage and support international collaboration in innovation and creativity',
  g4: 'Promote green technology to underpin a sustainable future and contribute to designing a green world',
  g5: 'Give an opportunity for existing and new businesses to showcase their ideas, connect with potential partners and attract investment.',
  ThreeTHEMES: 'Three THEMES',
  ThreeTHEMES1: 'A：Arts and Design , B：Innovation and Creativity , C：E-Business with T-mall',
  ThreeTHEMES2: 'Note, in respect of each theme, proposals reflecting green technology, green products and a commitment to sustainability and future are particularly welcome.',
  ThreeTHEMES3: 'A：Arts and Design ',
  ThreeTHEMES4: 'Proposals are invited in areas including:',
  ThreeTHEMES5: 'Culture and Creative Design',
  ThreeTHEMES6: 'Industry Design e.g. product design, package design, visual design and Environment design',
  ThreeTHEMES7: 'Fashion  Design',
  ThreeTHEMES8: 'Innovative Design(e.g. digital products, new materials) ',
  ThreeTHEMES9: 'B：Innovation and Creativity',
  ThreeTHEMES10: 'This theme is jointly held with Shanghai Nanxiang Shineland Creative Park.',
  ThreeTHEMES11: 'Nanxiang Shineland Park is located in Nanxiang township, Shanghai city. Nanxiang township is a historical place with thousands of years of history. There are more than 200 foreign-invested enterprises in Nanxiang and over 30,000 privately-owned enterprises. The park is in the center of the town, covering an area of 110.9 hectares with a total construction area of 500,000 square meters. ',
  ThreeTHEMES12: 'The winner of this theme will be offered a place in the Park and a number of supportive policies, including taxation benefits and business launch fund.',
  ThreeTHEMES13: 'C: E-Business with T-mall and other major e-commerce platforms',
  ThreeTHEMES14: 'The winning projects in this theme will obtain a “green channel” to open an e-shop on T-mall and other major e-commerce platforms and receive support from the Top 10 Taobao partner operation and marketing companies.',
  ThreeTHEMES15: 'Alibaba T-mall was launched as a spin-off from Taobao in April 2008for business-to-consumer online retail. It is operated in China by Alibaba Groupfor both local Chinese and international businesses to sell brand name goods to consumers mainly in China but also internationally. . It has over 500 million monthly active users and 180,000 brands. It is the worlds third most visited website according to Alexa. ',
  ThreeTHEMES16: '',
  ThreeTHEMES17: 'Details',
  ThreeTHEMES18: 'Who can apply? ',
  ThreeTHEMES19: 'Students ',
  ThreeTHEMES20: 'Young Entrepreneurs ',
  ThreeTHEMES21: 'Small and Medium Enterprises ',
  ThreeTHEMES22: 'New Idea Development  or Launch Plan from successful business and companies ',
  ThreeTHEMES23: 'Not-for-Profit organizations ',
  ThreeTHEMES24: '',
  ThreeTHEMES25: 'Attending gives an opportunity to:',
  ThreeTHEMES26: 'gain project investment funds',
  ThreeTHEMES27: 'join CECC IDC network',
  ThreeTHEMES28: 'set-up business in the Creative Industry Park',
  ThreeTHEMES29: 'workwith T-mall of Alibaba &Top 10 Taobao partner ',
  ThreeTHEMES30: 'present at key exhibitions with IDC in China',
  ThreeTHEMES31: 'participate in the Creativity World Forum (time and venue to be confirmed)',
  ThreeTHEMES32: 'Proposals are invited in areas including: ',
  ThreeTHEMES33: 'Culture and Creative Design',
  ThreeTHEMES34: 'Industry Design(e.g. product design, package design & visual design)',
  ThreeTHEMES35: 'Fashion  Design',
  ThreeTHEMES36: 'Innovative Design(e.g. digital products, new materials) ',
  ThreeTHEMES37: '',
  ThreeTHEMES38: '',
  ThreeTHEMES39: 'Key dates',
  ThreeTHEMES40: 'Submission and Peer Review',
  ThreeTHEMES41: 'First Round Revision',
  ThreeTHEMES42: 'First Round Decision',
  ThreeTHEMES43: 'Demo Submission',
  ThreeTHEMES44: 'Final Round Revision',
  ThreeTHEMES45: 'Final Result and Award Ceremony',
  ThreeTHEMES46: 'July 01st - October 30th, 2020',
  ThreeTHEMES47: 'November 01st  - November 15th, 2020',
  ThreeTHEMES48: 'November 16th, 2020',
  ThreeTHEMES49: 'November 16th  - December 05th, 2020',
  ThreeTHEMES50: 'December 08th - December 18th, 2020',
  ThreeTHEMES51: 'December, 2020',
  ThreeTHEMES52: 'Awards for Each Theme',
  ThreeTHEMES53: 'Theme A: Arts and Design',
  ThreeTHEMES54: 'First Prize:1;	50,000 RMB',
  ThreeTHEMES55: 'Certificate of Honor issued by YRD CECC',
  ThreeTHEMES56: 'Giving Keynote Speech at the Award Ceremony of IDC2020(free accommodation and local transportation)',
  ThreeTHEMES57: 'Showcase in Nanxiang Shineland Park in Shanghai',
  ThreeTHEMES58: 'Opportunity to set-up business in Nanxiang Shineland Park  in Shanghai with set-up fund and taxation incentives',
  ThreeTHEMES59: 'Second Prize:3;	20,000 RMB',
  ThreeTHEMES60: 'Certificate of Honor issued by YRD CECC',
  ThreeTHEMES61: 'Giving Keynote Speech at the Award Ceremony of IDC2020(free accommodation and local transportation)',
  ThreeTHEMES62: 'Showcase in Nanxiang Shineland Park in Shanghai',
  ThreeTHEMES63: 'Opportunity to set-up business in Nanxiang Shineland Park  in Shanghai with set-up fund and taxation incentives',
  ThreeTHEMES64: 'Third Prize: 5;	6,000 RMB',
  ThreeTHEMES65: 'Certificate of Honor issued by YRD CECC',
  ThreeTHEMES66: 'Giving Keynote Speech at the Award Ceremony of IDC2020(free accommodation and local transportation)',
  ThreeTHEMES67: 'Showcase in Nanxiang Shineland Park in Shanghai',
  ThreeTHEMES68: 'Opportunity to set-up business in Nanxiang Shineland Park  in Shanghai with set-up fund and taxation incentives',
  // ThreeTHEMES69: 'Nominee Awards: 10;	3,000 RMB Bonus',
  ThreeTHEMES69: 'Nominee Awards: 30;	1,000 RMB Bonus',
  ThreeTHEMES70: 'Certificate of Honor issued by YRD CECC',
  ThreeTHEMES71: 'Attending the Award Ceremony of  IDC2020(free meals and local transportation)',
  ThreeTHEMES72: 'Showcase in Nanxiang Shineland Park in Shanghai',
  ThreeTHEMES73: 'Opportunity to set-up business in Nanxiang Shineland Park  in Shanghai with set-up fund and taxation incentives',
  ThreeTHEMES74: 'Best Newcomer Awards,100',
  ThreeTHEMES75: 'Certificate of Honor issued by YRD CECC',
  ThreeTHEMES76: 'Attending the Award Ceremony of  IDC2020(free meals and local transportation)',
  ThreeTHEMES77: 'Showcase in Nanxiang Shineland Park in Shanghai',
  ThreeTHEMES78: 'In the event that  candidates are unable to travel  to China for the competition or the ceremony are not allowed because of COVID-19, all rights and arrangements will be held until the restrictions have  been lifted',
  ThreeTHEMES79: 'Theme B: Innovationand Creativity',
  ThreeTHEMES80: 'First Prize:1',
  ThreeTHEMES81: 'Opportunity to gain 1 million RMB project investment ',
  ThreeTHEMES82: 'Opportunity to set-up business in Nanxiang Shineland Park  in Shanghai with financial support and taxation incentives',
  ThreeTHEMES83: 'Certificate of Honor issued by YRD CECC',
  ThreeTHEMES84: 'Giving Keynote Speech at the Award Ceremony of IDC2020(free accommodation and local transportation)',
  ThreeTHEMES85: 'Attending Creativity World Forum(free tickets and meals and local transportation)',
  ThreeTHEMES86: '',
  ThreeTHEMES87: 'Second Prize:6',
  ThreeTHEMES88: 'Opportunity to 500,000 RMB project investment ',
  ThreeTHEMES89: 'Opportunity to set-up business in Nanxiang Shineland Park  in Shanghai with financial support and taxation incentives',
  ThreeTHEMES90: 'Certificate of Honor issued by  YRD CECC',
  ThreeTHEMES91: 'Giving Keynote Speech at the Award Ceremony of IDC2020(free accommodation and local transportation)',
  ThreeTHEMES92: 'Attending Creativity World Forum(free tickets and meals and local transportation)',
  ThreeTHEMES93: '',
  ThreeTHEMES94: 'Third Prize:30',
  ThreeTHEMES95: 'Opportunity to gain 200,000RMBproject investment fund',
  ThreeTHEMES96: 'Opportunity to set-up business in Nanxiang Shineland Park  in Shanghai with financial support and taxation incentives',
  ThreeTHEMES97: 'Certificate of Honor issued by YRD CECC',
  ThreeTHEMES98: 'Attending the Award Ceremony of  IDC2020(free meals and local transportation)',
  ThreeTHEMES99: 'Attending Creativity World Forum(free tickets and meals and local transportation)',
  Three1: 'Nominee Awards:66',
  Three2: 'Opportunity to set-up business in Nanxiang Shineland Park in Shanghai with financial support and taxation incentives',
  Three3: 'Certificate of Honor issued by YRD CECC',
  Three4: 'Attending the Award Ceremony of  IDC2020(free meals and local transportation)',
  Three5: '',
  Three6: '',
  Three7: 'Theme C：E-Business with T-mall',
  Three8: 'Investment funds will only be allocated on condition that business is set up in Nanxiang Shineland Park.',
  Three9: 'Background',
  Three10: "Alibaba T-mall was launched as a spin-off from Taobao in April 2008for business-to-consumer online retail. It is operated in China by Alibaba Groupfor both local Chinese and international businesses to sell brand name goods to consumers mainly in China but also internationally. It has over 500 million monthly active users and 180,000 brands. It is the world's third most visited website according to Alexa.",
  Three11: '',
  Three12: '',
  Three13: '',
  Three14: '',
  Three15: '',
  Three16: '',
  Three17: 'The winning projects in this theme will obtain a “green channel” to open an e-shop on T-mall and other major e-commerce platforms and receive support from the Top 10 Taobao partner operation and marketing companies.',
  Three18: '',
  Three19: '',
  Three20: '',
  Three21: '',
  Three22: '',
  Three23: 'Awards of Best Brands',
  Three24: 'Opportunity to establish a flagship store on T-mall of Alibaba',
  Three25: 'Certificate of Honor issued by YRD CECC',
  Three26: 'Attending the Award Ceremony of  IDC2020 (free meals and local transportation)',
  Three27: '',
  Three28: '',
  Three29: 'Note',
  Three30: 'In the event that  candidates are unable to travel  to China for the competition or the ceremony are not allowed because ofCOVID-19, all rights and arrangements will be held until the restrictions have  been lifted  ',
  Three31: 'Investment funds will only be allocated on condition that business is set up in Nanxiang Shineland Park.',
  Three32: '',
  Three33: 'Background',
  Three34: 'YRD City Creative Economy Cooperation Committee(CECC) is a regional inter-governmental  agency approved by YRD City Economy Coordination Council (‘the Council’), comprising  eight senior  leaders from Shanghai, Jiangsu Province, Zhejiang Province and Anhui Province.The Council holds an annual meeting in different cities, attended by mayors or vice mayors. The Executive President is based in Shanghai and the permanent Liaison Office is hosted by Shanghai Municipal Government Reforimg and Development Office (more details can be found at: (http://cecc.sh.cn/en/page/html/company.php).  ',
  Three35: 'The plan builds on previous initiatives for YRD, the first of which was in 1992, when 15 cities established a joint mechanism for economic cooperation. In 2018, the integration of the YRD was upgraded to a national-level strategy. (http://www.chinadaily.com.cn/regional/2020-06/01/content_37536491.htm)  ',
  Three36: 'YRD City Creative Economy Cooperation Committee(CECC) is a regional inter-governmental  agency approved by YRD City Economy Coordination Council (‘the Council’), comprising  eight senior  leaders from Shanghai, Jiangsu Province, Zhejiang Province and Anhui Province.The Council holds an annual meeting in different cities, attended by mayors or vice mayors. The Executive President is based in Shanghai and the permanent Liaison Office is hosted by Shanghai Municipal Government Reforimg and Development Office (more details can be found at: (http://cecc.sh.cn/en/page/html/company.php).',
  Three37: 'Submission and fee',
  Three38: 'International Design Competition 2021 is open for submissions now! The deadline to submit is June 30, 2022. All the participating teams (module a, module B, module c) submit through official website.',
  Three39: 'The submission does not charge any fee, but the delivery, insurance and Customs Service charges as incurred are chargeable to participants.',
  Three40: 'Contact',
  Three41: 'Official Website: https://yrd.cecc.sh.cn/#/brandActivity/IDC',
  Three42: 'Email: CECC2016@126.com',
  Three43: 'Wechat: 13482131639',
  Three44: 'Awards'
}
let award = {
  A1: 'Please Select Theme',
  A2: 'Arts and Design',
  A3: 'Innovation and Creativity',
  A4: 'E-Business with T-mall',
  A5: 'Please Select Proposal',
  A6: 'Culture and Creative Design',
  A7: 'Fashion  Design',
  A8: 'Industry Design (e.g.product design, packaging and visual design)',
  A9: 'Innovative Design(e.g. digital products, new materials)',
  A10: 'Please Select Project Status',
  A11: 'Concept Only',
  A12: 'DEMO/Model',
  A13: 'Launched on the Market',
  A14: 'If have already been launched on the market, do you need:',
  A15: 'Incubation funds',
  A16: 'Online promotion',
  A17: 'Offline channel',
  A18: 'Project Information',
  A19: 'Project Name',
  A20: 'Brief Description',
  A20_1: 'eg. furniture, clothing',
  A21: 'Save',
  A22: 'Project Description ',
  A23: 'Project Positioning',
  A24: 'Innovation',
  A25: 'Project Display',
  A26: 'Main illustration',
  A27: 'PNG/JPG format, less than 3M，one picture，Square(210mm*210mm), no text，300dpi, RGB color.',
  A28: 'Detail product images',
  A29: 'PNG/JPG format, less than 3M，Max 4 picture，A4(210mm*297mm),  Land-scape/Portrait, 300dpi, RGB color.',
  A30: 'Video Link (Option) ',
  A31: 'Team information',
  A32: 'Project Owner',
  A33: 'Project Designer',
  A34: 'Main Team Members',
  A35: 'Member Two',
  A36: 'Member Three',
  A37: 'Member Four',
  A38: 'Member Five',
  B1: 'Please Select Proposal Environmental technology and products technology',
  B2: 'Creative Design Products',
  B3: 'Smart Products',
  B4: 'Health products',
  B5: 'Others',
  B6: 'Please Select Project Status',
  B7: 'Concept Only',
  B8: 'DEMO/Model',
  B9: 'Launched on the Market',
  B10: 'If have already been launched on the market, do you need: ',
  B11: 'Incubation funds',
  B12: 'Online promotion',
  B13: 'Offline channel',
  B14: 'Brand and design empowerment',
  B15: 'Technology and supply chain channel',
  B16: 'Basic business information',
  B17: 'Name of business plan',
  B18: 'Description',
  B19: 'Profit model',
  B20: 'Business plan upload',
  B21: 'PDF format, less than 10M.',
  B22: 'Please select enterprise type (multiple choices)',
  B23: 'Factory',
  B24: 'Brand',
  B25: 'Dealer',
  B26: 'Brand Basic Information',
  B27: 'Basic Information',
  B28: 'Company name',
  B29: 'Company status',
  B30: 'Company Business',
  B31: 'Company address',
  B32: 'Please select product type',
  B33: 'Beauty products',
  B34: 'Culture promotion products',
  B35: 'Food and drink',
  B36: 'Others',
  B37: 'Brand main products',
  B38: 'Please select type of main products',
  B39: 'Electronic product',
  B40: 'Clothing',
  B41: 'Daily Necessities',
  B42: 'Culture promotion products',
  B43: 'Service',
  B44: 'Household fit-up and decoration',
  B45: 'Others',
  B46: 'Please select product requirements (multiple choices)',
  B47: 'Incubation funds',
  B48: 'Online promotion',
  B49: 'Offline channel',
  B50: 'Brand and design empowerment',
  B51: 'Technology and supply chain channel',
  B51_1: 'Overseas market expansion',
  B52: 'Please select whether there is an existing shop',
  B53: 'Please select annual online sale',
  B54: 'Please select annual offline sale',
  B55: 'Yes',
  B56: 'No',
  B57: 'No',
  B58: 'Brand online information (brand homepage will be generated)',
  B59: 'Brand name',
  B60: 'Brand profile',
  B61: 'Product description',
  B62: 'Product selling points and advantages',
  B63: 'Brand Logo Upload',
  B64: 'PNG/JPG format, less than 1M, 1 picture only, square (50mm * 50mm), no text, 300dpi, RGB color.',
  B65: 'Brand Image Upload',
  B66: 'PNG/JPG format, less than 1M, Max 3 pictures, landscape (174mm*100mm), no text, 300dpi, RGB color.',
  B67: 'Video link (Optional)',
  B68: 'Preview Brand Homepage',
  B69: '',
  B70: '',
}
let base = {
  H1: 'HOME',
  H2: 'ABOUT US',
  H3: 'SERVICE',
  H4: 'ACTIVITIES',
  H5: 'CONTACT',
  H6: 'ACCOUNT',
  H7: '0 - 50 ppl',
  H8: '50 - 200 ppl',
  H9: '200 - 500 ppl',
  H10: '500 - 1000 ppl',
  H11: '> 1000 ppl',
  H12: 'CWF 2020',
  H13: 'IDC 2020',
  H14: 'JiangNan',
  H15: 'WUWEI Centre',
  H16: "Masters' View",
  H17: "Creativity Info",
  H18: "Study Tour",
  H19: '',
  H20: 'Ramón Vila',
  H21: 'Art Director at Disneyland',
  H22: 'Design the decoration for all seasonal events',
  H23: 'like Halloween, Chinese New Year, Christmas,',
  H24: 'Opening Events, red carpets, and parade floats, etc. ',
  H25: '',
  H26: '',
  H27: '',
  H28: '',
  H29: '',
  H30: 'Guanzhong Liu',
  H31: 'Professor and PhD mentor at the Tsinghua University’s Academy of Design & Arts',
  H32: 'Director and honorary vice president of the Expert Committee of China Industrial Design',
  H33: '',
  H34: '',
  H35: '',
  H36: '',
  H37: '',
  H38: '',
  H39: '',
  H40: 'Jun Li',
  H41: 'President, professor and PhD at the Donghua University’s Shanghai International College of Fashion and Innovation',
  H42: 'PhD mentor of Fashion Design and Digital Media Arts',
  H43: '',
  H44: '',
  H45: '',
  H46: '',
  H47: '',
  H48: '',
  H49: 'Éloi Beauchamp',
  H50: 'L’Éloi Founder',
  H51: 'Canadian top photographer',
  H52: 'Production L’Éloi founded in 2000 in Montreal',
  H53: 'Represent true artists, Photographers, cinematographers, ',
  H54: 'art directors, stylists, hair & make-up artists.',
  H55: '',
  H56: '',
  H57: 'Please fill with the video link',
  H58: '',
  H59: '',
  H60: 'Please fill with project positioning',
  H61: 'Please fill with innovation',
  H62: 'Name',
  H63: 'Responsilility',
  H64: 'Nationality',
  H65: 'Identity',
  H66: 'Individual',
  H67: 'Team',
  H68: 'Please fill the blank',
  H69: 'Please fill the blank',
  H70: 'Please fill the blank',
  H71: 'Please fill the blank',
  H72: 'Please select specific type',
  H73: 'Please fill the blank',
  H74: 'Please fill the blank',
  H75: 'Please fill the blank',
  H76: 'Please fill the blank',
  H77: 'Below 10 million',
  H78: '10M - 50M',
  H79: '50M - 100M',
  H80: '100M - 1B',
  H82: 'Above 1 billion',
  H83: 'Please fill with address',
  H84: 'Please fill the blank',
  H85: '',
  H86: 'Please fill the blank eg.chair',
  H87: 'Please fill the blank eg.chair',
  H88: 'Name of main product',
  H89: 'Annual Sale',
  H90: 'Trademark Status',
  H91: 'Are you a taxpayer',
  H92: 'Brand Name',
  H93: 'Founded Time',
  H94: 'Company Name',
  H95: 'Registered Capital',
  H96: 'The video should be less than 3 minutes. 1280 * 720 (720p) is recommended.',
  L1: 'Intangible Cultural Heritage',
  L2: 'Art Exhibition',
  L3: 'E-sports',
  L4: 'Creative Design',
  L5: 'Others',
  L6: 'Electronic Technology',
  L7: 'Smart Product Development & Application',
  L8: 'Artificial Intelligence',
  L9: 'Smart Materials',
  L10: 'Smart Car',
  L11: 'UAV Operation',
  L12: 'Environmental technology and products technology',
  L13: 'Others',
  L14: 'Biotechnology',
  L15: 'Medical instruments',
  L16: 'Gene sequencing',
  L17: 'Experimental platform',
  L18: 'Targeted Therapy',
  L19: 'Others',
  L20: 'Before 1990',
  L21: '1990-2000',
  L22: '2000-2010',
  L23: 'After 2010',
  moreDemands: 'More Demands',
  L24: 'Success',
  L25: 'Please fill the blank.',
  L26: 'Registered Success',
  L27: 'Login Success',
  L28: 'error',
  L29: 'This page is pending',
  L30: '',
  L31: 'Back',
  Q1: 'About CECC',
  Q2: '',
  Q3: "The Yangtze River Delta (YRD) is an economic powerhouse of modern China. It is approximatelythe size of Germany and encompasses Shanghai and the provinces of Jiangsu, Zhejiang, and Anhui. The area is home to 15% of China's population and contributes around a quarter of the country's GDP and more than a third of its foreign trade and investment in 2019, with a value of 11.24 trillion yuan ($1.58 trillion). In December 2018, the Chinese Central Government unveiled an ambitious plan further to develop and integrate the region over the next 15 years and in  a way that will enhance the contribution of YRD to the national and global economy.",
  Q4: 'The plan builds on previous initiatives for YRD, the first of which was in 1992, when 15 cities established a joint mechanism for economic cooperation. In 2018, the integration of the YRD was upgraded to a national-level strategy. (http://www.chinadaily.com.cn/regional/2020-06/01/content_37536491.htm)',
  Q5: "YRD City Creative Economy Cooperation Committee(CECC) is a regional inter-governmental  agency approved by YRD City Economy Coordination Council (‘the Council’), comprising  eight senior  leaders from Shanghai, Jiangsu Province, Zhejiang Province and Anhui Province.The Council holds an annual meeting in different cities, attended by mayors or vice mayors. The Executive President is based in Shanghai and the permanent Liaison Office is hosted by Shanghai Municipal Government Reforimg and Development Office (more details can be found at: (http://cecc.sh.cn/en/page/html/company.php).",
  Q6: "According to the request of the mayor's joint meeting, under the leadership of the Yangtze River Delta Urban Economic Coordination Committee, Creative Economy Cooperation Committee(CECC) as the coordinate with 41 members of the city to build creative driven development mode to provide high quality professional service, promoting integration of the regional industry cluster based on the model of “Shanghai Design +Yangtze River Delta Industry Chain”. CECC is also a member of Districts of Creativity Network.",
  Q7: 'Organization:',
  Q8: 'Leading Organization: Shanghai Municipal Cultural and Creative Industry Promotion Leading Group Office; Shanghai Economic and Information Technology Committee Urban Industry',
  Q26: 'Secretary-general Office : Donghua University',
  Q9: 'Service Scope:',
  Q10: '1. Building an international creative economy exchange platform and show the international image of Yangtze River Delta cities by holding Creative World Forum, editing magazines, new media communication and other means;',
  Q11: "2. Preparing for the establishment of the YRD Creative Economy Research Center Think Tank, gathering the world's top creative talents, and providing decision-making consulting services for the integrated development of the Yangtze River Delta creative economy;",
  Q12: '3. Connecting the resources of high-quality cultural and creative projects globally, promoting the establishment of creative incubator industry fund. We also help member cities with professional services in the planning and operation of creative parks. We can provide professional services for the renovation and upgrading of cultural and creative industrial complex projects;',
  Q13: '4. Irregularly hold research and discussion activities to solve hot issues in urban development and difficulties in the transformation of traditional industries. We grasp the development trend of creative economy, organize corresponding training activities and invite experts to make on-site diagnosis；',
  Q14: '5. Strengthen contacts with international creative economy organizations and foreign creative cities, and explore new mechanisms for international cooperation in creative economy. At the same time, we assist member cities in the Yangtze River Delta to conduct accurate international investment and promote the listing of relevant enterprise brands, so as to tell Chinese story to the world.',
  Q15: 'Location',
  Q16: '',
  Q17: 'Shanghai, China',
  Q18: "CECC Address: Changning District City, West Yan'an Road 1882,  Xuri Building.",
  Q19: 'Wuwei Address: Jiading District City, Huyi Road 1188, No.24 Buliding',
  Q20: 'Tel: 021-62378609',
  Q21: 'Fax: 021-62373399',
  Q22: 'E-mail: cecc2016@126.com',
  Q23: 'Leuven, Belgium',
  Q24: 'Districts of Creativity Network, c/o Flanders DC, Diestsevest 76',
  Q25: 'E-mail: cecc2016@126.com',
  Q27: 'ABOUT CECC',
  Q28: '',
  Q29: "According to the request of the mayor's joint meeting, under the leadership of the Yangtze River Delta Urban Economic Coordination Committee, Creative Economy Cooperation Committee(CECC) as the coordinate with 41 members of the city to build creative driven development mode to provide high quality professional service, promoting integration of the regional industry cluster based on the model of “Shanghai Design +Yangtze River Delta Industry Chain”. CECC is also a member of Districts of Creativity Network.",
  Q30: 'Please fill the blank',
  Q31: 'CITY IMPRESSION',
  Q32: 'GLOBAL ORIGINAL',
  Q33: 'ANNUAL AWARD',
  Q34: 'HOT SALES',
  Q35: 'EXPERTS RECOMMEND',
  Q36: 'HOT COMMMENTS',
  moreM: 'Portfolio',
  Q37: 'Created successfully',
  Q38: 'Submit',
  Q39: 'The work information cannot be modified after submission. Are you sure to submit ?',
  Q40: 'Add New',
  Q41: 'Delete',
  Q42: 'Name',
  Q43: 'ID',
  Q44: 'Status',
  Q45: 'Module',
  Q46: 'Submitted',
  Q47: 'draft',
  Q48: 'Modify',
  Q49: 'View',
  Q50: 'The deleted work cannot be restored. Are you sure to delete it?',
  Q51: 'Sync successfully',
  Q52: 'Creative Industry Innovation Ecosystem: Intellectual Evolution and Development Trends',
  Q53: 'Nowadays, many creative industry clusters in China are rushing toward the development of a creative ecological model of “culture+e-commerce” and “academy+parks”, with the aim of accelerating the development of brand building and derivative markets, creating a complete creative industry innovation ecosystem. At the same time, the boundless nature of creativity has enabled scholars’ research on creative industries to change from the perspective of an industrial chain to innovation ecosystem.',
  Q54: 'Masters’ View',
  Q55: 'Creative Journey in Europe (IAT) Program',
  Q56: 'CECC is the co-organizer of the CJE program and responsible for the promotion of the CJP and recruitment of the participants to the CJP program, as well as the coordination with AMS. AMS is the main organizer of the CJE program and responsible for design, development and delivery of the program, including all visits and events as presented in the program schedule. All parties shall work closely to guarantee the execution of the program, including all necessary organization work, e.g. visa application, transportation, accommodation, meal, etc.',
  Q57: 'Study Tour',
  Q58: 'READ MORE',
  Q59: '2019 Shanghai World Creative Economy Summit',
  Q60: '2019 (5th) Shanghai World Creative Economy Summit(SWCES) highlighted the core driving factors of IP, design and digital experience in the development of creative economy. 2019 SWCES cooperated with Shanghai Design Week to disseminate new global design concepts, new products, new applications and new models. Create a community of design dreams, collide the design with culture, art, technology and business, and realize the concept of “design and start again” from the concept, behavior and product carrier, and provide indicators for the development of creative cities, parks and industries.',
  Q61: '2018 Shanghai World Creative Economy Summit',
  Q62: 'Creativity and Industry Park" as the theme and invited Montreal, Canada as the main guest city. 2018 SWCES was based on the boundless penetration of creativity, international communication and industrial drive, introducing international intellectual resources, building an international platform for sharing resources of creative economy, and gather the ideological wisdom of the eastern and western creative economy. It provided indicators for the development of creative cities, creative parks and the development of creative industries, and facilitated the promotion of global creative economy development strategies and the integration of industries.',
  Q63: '2017 Shanghai World Creative Economy Summit',
  Q64: '2017 (3th) Shanghai World Creative Economy Summit(SWCES) aimed to use creativity as a guide to explore and revitalize traditional cultural resources, focusing on the coordinated development of China\'s creative economy across regions and across borders. The summit forum selected Copenhagen as the main guest city. Through the theme of “Connecting Creativity and Smart Life”, the summit was jointly organized by Nanxiang, Shanghai and Songyang, Zhejiang, to show the theoretical influence of the CECC academic community and the ability to integrate resources in the industry and the intellectual service capacity of the society. 2017 SWCES was honored by the Office of the Yangtze River Delta Economy Coordination Council as the "Best Event" in the region.',
  Q65: '2016 Shanghai World Creative Economy Summit',
  Q66: 'The 2016(2th) Shanghai World Creative Economy Summit(SWCES) was held at Donghua University and Nanxiang Town of Jiading District in Shanghai, inviting guest speakers mainly from Brugge, Belgium. The theme of the summit was " Internet creative, sharing all the chips". The number of participants in the academic forum was 360 people, and that in the main forum and the three sub-forums of the summit exceeded 400.',
  Q67: 'Finalists',
  moreGuests: 'More guests',
  morePhotos: 'More photos',
  CWF: 'CWF',
  IDCT: 'IDC',
  aboutWuwei: 'ABOUT Wuwei',
  Wuwei: "Shanghai Wuwei Creative Economy Region Cooperation Promotion Centre is supported by Prof Wuwei Li, the Founder of Chinese Creative Economy, and the Vice-Chairman of the 11th Chinese People's Political Consultative Conference, initiated by Yangtze River Delta Creative Economy Cooperation Committee (CECC).The Wuwei Centre is a non-governmental organisation that provides a platform for business, non-profit organisations, professionals and young people from all countries to promote the idea of a creative economy and support domestic and international collaboration."
}
export default {
  ...IDC,
  ...User,
  ...details,
  ...award,
  ...base
};