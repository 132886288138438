import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const VueRouterPush = Router.prototype.push
Router.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

export const constantRouterMap = [
  {
    path: '/',
    component: () => import('@/views/home.vue'),
    children: [
      {
        path: '',
        name: 'home',
        title: 'H1',
        showMenu: true,
        component: () => import('@/views/introduce.vue')
      },
      {
        path: 'aboutUs',
        name: 'aboutUs',
        title: 'H2',
        showMenu: true,
        component: () => import('@/views/about-us.vue'),
      },
      {
        path: 'serviceDirectory',
        title: 'H3',
        showMenu: true,
        component: () => import('@/views/service-directory.vue'),
        children: [
          {
            path: 'masterView',
            name: 'masterView',
            title: 'H16',
            component: () => import('@/views/master-view.vue')
          },
          {
            path: 'creativityInfo',
            name: 'creativityInfo',
            title: 'H17',
            component: () => import('@/views/creativity-info.vue')
          },
          {
            path: 'studyTour',
            name: 'studyTour',
            title: 'H18',
            component: () => import('@/views/study-tour.vue')
          }
        ]
      },
      {
        path: 'brandActivity',
        title: 'H4',
        showMenu: true,
        component: () => import('@/views/brand-activity.vue'),
        children: [
          {
            path: 'CWF',
            name: 'CWF',
            title: 'H12',
            component: () => import('@/views/CWF.vue')
          },
          {
            path: 'IDC',
            name: 'IDC',
            title: 'H13',
            component: () => import('@/views/IDC.vue')
          },
          {
            path: 'fairviewJiangnan',
            name: 'fairviewJiangnan',
            title: 'H14',
            component: () => import('@/views/fairview-jiangnan.vue')
          },
          {
            path: 'culturalTour',
            name: 'culturalTour',
            // title: '文化巡礼嗨客行',
            title: 'H15',
            component: () => import('@/views/fearless-center.vue')
          }]
      },
      {
        path: 'company/:id',
        name: 'company',
        showMenu: false,
        component: () => import('@/views/company-home.vue')
      },
      {
        path: 'companyPreview',
        name: 'companyPreview',
        showMenu: false,
        component: () => import('@/views/company-preview.vue')
      },
      {
        path: 'contactInformation',
        name: 'contactInformation',
        title: 'H5',
        showMenu: true,
        component: () => import('@/views/aboutUs-details.vue')
      },
      {
        path: 'aboutUs/details',
        name: 'aboutUs/details',
        showMenu: false,
        component: () => import('@/views/aboutUs-details.vue')
      },
      {
        path: 'brandActivity/details',
        name: 'brandActivity/details',
        showMenu: false,
        component: () => import('@/views/jiangnan-details.vue')
      },
      {
        path: 'morePhotos',
        name: 'morePhotos',
        showMenu: false,
        component: () => import('@/views/more-photos.vue')
      },
      {
        path: 'IDC/details',
        name: 'IDC/details',
        showMenu: false,
        component: () => import('@/views/IDC-details.vue')
      },
      {
        path: 'IDC/masters',
        name: 'IDC/masters',
        showMenu: false,
        component: () => import('@/views/IDC-masters.vue')
      },
      {
        path: 'wuwei/details',
        name: 'wuwei/details',
        showMenu: false,
        component: () => import('@/views/fearless-center-details.vue')
      },
      {
        path: 'personCenter',
        name: 'personCenter',
        showMenu: false,
        component: () => import('@/views/person-center.vue')
      },
      {
        path: 'personCenterCopy',
        name: 'personCenterCopy',
        showMenu: false,
        component: () => import('@/views/person-center-c.vue')
      },
      {
        path: '/login',
        name: 'login',
        component: () => import('@/views/login.vue')
      },
      {
        path: '/reset_password',
        name: 'reset_password',
        component: () => import('@/views/reset-password.vue')
      },
      {
        path: '/reset_password_sure',
        name: 'reset_password_sure',
        component: () => import('@/views/reset-password-sure.vue')
      },
      {
        path: '/building',
        name: 'building',
        component: () => import('@/views/building.vue')
      },
      {
        path: '/recommend',
        name: 'recommend',
        component: () => import('@/views/recommend.vue')
      },
      {
        path: '/demandView',
        name: 'demandView',
        component: () => import('@/views/demand-view.vue')
      },
      {
        path: '/demandViewGu',
        name: 'demandViewGU',
        component: () => import('@/views/demand-view-gu.vue')
      },
      {
        path: '/demandViewNan',
        name: 'demandViewNan',
        component: () => import('@/views/demand-view-nan.vue')
      },
      {
        path: '/demandList',
        name: 'demandList',
        component: () => import('@/views/demand-list.vue')
      },
      {
        path: '/designProductList',
        name: 'designProductList',
        component: () => import('@/views/design-product-list.vue')
      },
      {
        path: '/moreNews',
        name: 'moreNews',
        component: () => import('@/views/more-news.vue')
      },
      {
        path: 'news',
        name: 'news',
        showMenu: false,
        component: () => import('@/views/news.vue')
      },
      {
        path: '/awardsList',
        name: 'awardsList',
        component: () => import('@/views/awards-list.vue')
      },
      {
        path: '/showList',
        name: 'showList',
        component: () => import('@/views/show-list.vue')
      }
    ]
  },
  {
    path: '/',
    component: () => import('@/views/home.vue'),
    children: [
      {
        path: 'en',
        name: 'home_en',
        title: 'H1',
        showMenu: true,
        component: () => import('@/views/introduce_en.vue')
      },
      {
        path: 'aboutUsEn',
        name: 'aboutUsEn',
        title: 'H2',
        showMenu: true,
        component: () => import('@/views/about-us_en.vue'),
      },
      {
        path: 'serviceDirectoryEn',
        name: 'serviceDirectoryEn',
        title: 'H3',
        showMenu: true,
        component: () => import('@/views/service-list.vue')
      },
      {
        path: 'brandActivity',
        title: 'H4',
        showMenu: true,
        component: () => import('@/views/brand-activity.vue'),
        children: [
          {
            path: '/CWF_en',
            name: 'CWF_en',
            title: 'CWF',
            component: () => import('@/views/CWF_en.vue')
          },
          {
            path: 'IDC',
            name: 'IDC',
            title: 'IDCT',
            component: () => import('@/views/IDC.vue')
          }]
      },
      {
        path: 'contactInformation_en',
        name: 'contactInformation_en',
        title: 'H5',
        showMenu: true,
        component: () => import('@/views/aboutUs-details.vue')
      }
    ]
  },
  {
    path: '/mobile',
    name: 'mobile',
    component: () => import('@/views/mobile/home.vue'),
    children: [
      // { path: '/', component: () => import('../views/mainPage.vue') }
    ]
  }
]

const router = new Router({
  mode: 'hash',
  routes: constantRouterMap
})

router.beforeEach((to, from, next) => {
  document.documentElement.scrollTop = 0;
  next()
})

export default router;
