import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import api from './fetch/index'
import ElementUI from 'element-ui'
import i18n from './language'
import 'element-ui/lib/theme-chalk/index.css'
import 'swiper/css/swiper.min.css'
import Cookies from 'js-cookie'
import VideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'
import 'vue-video-player/src/custom-theme.css'
Vue.use(VideoPlayer)


import 'normalize.css/normalize.css'

Vue.config.productionTip = false
Vue.prototype.$api = api
Vue.prototype.$Cookies = Cookies

Vue.use(ElementUI)

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
