
let IDC = {
  signUp: '报名参赛',
  readMore: '更多',
  rules: '赛事规则',
  // IDC_title1: '全球创意设计大赛2021',
  IDC_title1: '第二届全球创意设计大赛',
  IDC_title2: '大赛主题：绿色生活 城市IP',
  IDC_second1: '以“赛”聚才：',
  IDC_second1_1: '汇聚全球顶尖创意人才、创意作品和创新项目；',
  IDC_second2: '以“赛”兴业：',
  IDC_second2_1: '为优秀作品和项目提供基金及入驻中国长三角城市创意产业园区机会；',
  IDC_second3: '以“赛”联通：',
  IDC_second3_1: '为全球优品提供阿里天猫等线上平台商业转化渠道；',
  IDC_second4: '以“赛”发声：',
  IDC_second4_1: '选拔中国优秀作品、优质产品参加国内外展览，为中国设计发声。',
  IDC_main: '为深入贯彻落实习近平总书记重要讲话精神，奋力夺取疫情防控和实现长三角经济社会发展目标“双胜利”，在长三角城市经济协调会办公室指导下，由长三角创意经济专业委员会（简称CECC）牵头组织长三角全球创意设计大赛及其专属“锦绣江南”名品榜活动。该活动已被列入长三角城市经济协调会2020年度重点合作事项清单。',
  IDC_main1: '全球创意设计大赛定位为长三角世界级城市群建设的重要组成部分，主要推动设计成果产业化转化和商业化运作，延伸设计产业价值链；同时，将加重“上海设计”国际化元素，站位全球设计价值链发声，构建“上海设计+长三角产业链”一体化高质量发展模式，汇聚全球创意英才，传播长三角“原创名品”，向世界讲述“中国故事”。 ',
  IDC_main2: '大赛组委会将协同比利时世界创意论坛（CWF）、英国格拉斯哥、法国巴黎创意谷、东华-爱丁堡大学创意产业中心、西班牙Deusto大学创新孵化中心、丹麦科技大学SKYLAB、加拿大蒙特利尔顶尖数字创意企业、世界纺织大学联盟等国内外机构，进行全球发布并吸引全球优秀独立设计师、青年创客与国际创新创业学生及企业，以及长三角创意设计类高校师生共同参与，长三角城市经济协调会成员城市人民政府支持共同完成。',
  IDC_main2_1: '',
  IDC_main3: '',
  IDC_main4: '',
  IDC_main5: '',
  IDC_main6: '',
  IDC_main7: '',
  IDC_main8: '',
  IDC_main9: '',
  IDC_main10: '',
  IDC_main11: '',
  IDC_main12: '',
  IDC_third1: '欢迎你来赛',
  IDC_third2: '任何设计作品、创意项目和原创名品皆可参赛…有梦想，敢释放',
  IDC_third3_1: '谁来参赛',
  IDC_third3_2: '设计创意机构',
  IDC_third3_3: '自由创意设计师',
  IDC_third3_4: '商业创意机构',
  IDC_third3_5: '创业项目创始人',
  IDC_third3_6: '高校设计院/商学院师生',
  IDC_third3_7: '有创意作品/产品的企业',
  IDC_third4_1: '为何参赛',
  IDC_third4_2: '丰厚的项目孵化基金',
  IDC_third4_3: '天猫等网络销售渠道直通车',
  IDC_third4_4: '向全球展出作品的机会',
  IDC_third4_5: '入驻长三角创意人才库的机遇',
  IDC_third4_6: '参与世界创意经济峰会',
  IDC_third5_1: '参赛方向',
  IDC_third5_2: '文创设计',
  IDC_third5_3: '时尚设计',
  IDC_third5_4: '工业设计（产品设计、包装设计、视觉设计等）',
  IDC_third5_5: '创新设计（数字设计、新材料设计等创新设计）',
  IDC_forth1_1: '设计作品模块',
  IDC_forth1_2: '设计创业模块',
  IDC_forth1_3: '原创名品模块',
  IDC_forth2_1: '古堰画乡杯',
  IDC_forth2_2: '设计作品模块',
  IDC_forth2_3: '以设计作品参赛的赛事模块，分为自由探索的概念组和古堰画乡杯产业组。',
  IDC_forth2_4: '主要包含文创设计、时尚设计、工业设计（含产品设计、包装设计、视觉设计等）、创新设计（含数字设计、新材料设计等新设计）四大板块的参赛方向。',
  IDC_forth2_5: '城市印象文创类、食品、生活用品、化妆品、服装、珠宝行业应用优先。',
  IDC_forth2_6: '特别推出“企业设计项目”，面向长三角城市、产业园区及企业的设计需求设置命题赛事。',
  IDC_forth3_1: '南翔智地奖',
  IDC_forth3_2: '设计创业模块',
  IDC_forth3_3: '以“创业商业计划书”参赛，并进行项目路演的赛事模块，主要面向项目孵化需求的创意设计、数字智能、医疗健康等相关项目。【参赛方向与行业不限。】',
  IDC_forth3_4: '优先支持】进驻南翔智地园区项目、数字智能项目、创意设计项目、医疗健康项目。',
  IDC_forth3_5: '',
  IDC_forth3_6: '',
  IDC_forth3_7: '',
  IDC_forth3_8: '',
  IDC_forth3_9: '',
  IDC_forth4_1: '东方美谷猫',
  IDC_forth4_2: '原创名品模块',
  IDC_forth4_3: '以已上市作品、产品及品牌参赛，并进行作品汇报的赛事模块，主要面向品牌上线需求的设计作品与产品及品牌。【参赛方向与行业不限。】',
  IDC_forth4_4: '【优先支持】东方美谷研究院项目 、 原创名品项目、拟进驻天猫项目、拟拓展市场通路项目。',
  IDC_forth4_5: '',
  IDC_forth4_6: '',
  IDC_forth4_7: '',
  IDC_forth4_8: '',
  IDC_forth5_1: '以“赛”聚才：汇聚全球顶尖创意人才、创意作品和创新项目； 以“赛”兴业：为优秀作品和项目提供基金及入驻中国长三角城市创意产业园区机会； 以“赛”联通：为全球优品提供阿里天猫等线上平台商业转化渠道； 以“赛”发声：选拔中国优秀作品、优质产品参加国内外展览，为中国设计发声。',
  IDC_forth5_2: '',
  IDC_forth5_3: '',
  IDC_forth5_4: '',
  IDC_forth5_5: '',
}
let User = {
  welcome: '欢迎来到CECC，请登录',
  enterEmail: '请输入邮箱',
  enterPassword: '请输入账户密码',
  confirmPassword: '请确认您的密码',
  enterCode: '请输入验证码',
  forgetPassword: '忘记密码',
  createAnAccount: '没有账户，注册新账户',
  createAccount: '注册新用户',
  resetPassword: '重置密码',
  reset: '重置',
  logInWithAnotherAccount: '使用其他账号登录',
  login: '登录 ',
  register: '注册',
  whereToKnow: '您是通过什么渠道知道CECC的？(可多选)',
  where1: '曾参与往届峰会',
  where2: '媒体宣传',
  where3: '展览',
  where4: '宣讲会',
  where5: '他人介绍',
  where6: '其他',
  tips: '注意',
  tips1: ' 账号信息尚未完善，请核对打“·”号部分信息，完善个人账号信息后再进行下一步操作。',
  tips2: '请先勾选 我已阅读并同意注册条款 !',
  confirm: '确定',
  notice: '赛事通知',
  account: '账号信息',
  entryList: 'IDC作品管理',
  certificate: 'IDC证书下载',
  moreT: 'IDC参赛细则',
  // moreJ: '参报锦绣江南名品',
  moreJ: '锦绣江南名品',
  moreC: '企业需求',
  registrationInfo: '注册信息',
  accountName: '注册邮箱',
  changePassword: '修改密码',
  help: '帮助说明',
  logout: '退出登录',
  submitterInfo: '个人信息',
  name: '姓名',
  firstName: '请输入您的姓',
  lastName: '请输入您的名',
  identity: '所在单位类型',
  identitySelect: '请选择您所在单位类型',
  unitName: '企业名称',
  enterUnitName: '请输入企业名称',
  contactInfo: '联系信息',
  mobilePhone: '移动电话',
  contactPhone: '联系电话',
  Wechat: '微信号',
  enterTelPhone: '请输入移动电话',
  enterContactPhone: '请输入联系电话',
  enterTelWechat: '请输入微信号码',
  Email: '电子邮箱',
  fixedLineTelephone: '固定电话',
  enterFixedLineTelephone: '请输入固定电话',
  Region: '国家与地区',
  selectRegion: '请选择国家与地区',
  Ihaveread: '我已阅读并同意注册条款',
  NewsletterSubscription: '订阅赛事通知',
  saveInformation: '保存信息',
  modifyInformation: '修改信息',
  Enterprise: '企业',
  Institution: '院校(在校学生或教师)',
  InstitutionName: '院校名称',
  enterInstitutionName: '请输入学校名称',
  teacher: '教师',
  student: '学生',
  SocialIndividuals: '社会个人或组织',
  designCompany: '设计公司',
  Other: '其他',
  enterOther: '请输入单位类型',
  chooseEductionLevel: '请选择学历',
  Education: '学历',
  AcademicTitle: '职称',
  occupation: '职称',
  fillInYourOc: '请输入职称',
  fillInYourAc: '请输入职称',
  Doctorate: '博士研究生',
  Master: '硕士研究生',
  Bachelor: '本科或大专',
  GED: '高中或中专',
  employeeNumber: '规模',
  chooseNumberOfEmployees: '请选择规模',
  P1: '个人或组织名称',
  P2: '请输入个人或组织名称',
  P3: '公司名称',
  P4: '请输入公司名称',
  P5: '设计方向',
  P6: '请选择设计方向',
  P7: '请输入原密码',
  P8: '请输入4-8位由英文或数字组成的密码',
  P9: '请再次输入新密码',
  P10: '提交',
  P11: '账号信息尚未完善，请核对打“·”号部分信息',
  P12: '请输入您的密码',
  P13: '您两次输入的新密码不一样',
  P14: '修改密码成功，请重新登陆！',
  P15: '修改成功',
  P16: '您已报名其他模块比赛',
  P17: '点击刷新验证码',
  P18: '请输入验证码',
  P19: '资料下载',
  // P20: '2021 IDC赛事手册',
  // P21: '2021 IDC大赛海报',
  P20: '第二届 IDC赛事手册',
  P21: '第二届 IDC大赛海报',
  // P22: '2020 IDC报名指南',
  P22: '第二届 IDC报名指南',
  D1: '城市文化创意产品设计',
  D2: '旅游纪念品设计',
  D3: '城市特色产品设计（含食品、农产品等）',
  D4: '区域品牌设计',
  D5: '城市形象相关设计',
  D6: '其他',
  D7: '流行服饰',
  D8: '母婴用品',
  D9: '时尚穿戴产品设计',
  D10: '时尚产品创新设计',
  D11: '时尚周边产品设计',
  D12: '其他',
  D13: '电子数码产品',
  D14: '厨房卫浴家电',
  D15: '设备设施器具',
  D16: '化妆品品牌设计',
  D17: '特色产业产品包装设计',
  D18: '生活杂项用品',
  D19: '其他',
  D20: '城市特色主题数字创意设计',
  D21: '特色产业的新方向设计',
  D22: '新材料应用设计',
  D23: '技术创新应用',
  D24: '模式创新应用',
  D25: '其他',
  D26: '非遗文化',
  D27: '艺术展览',
  D28: '电子竞技',
  D29: '创意设计',
  D30: '其他',
  D31: '电子科技',
  D32: '智慧产品开发及应用',
  D33: '人工智能',
  D34: '智能材料',
  D35: '智慧汽车',
  D36: '无人机运用',
  D37: '环保节能产业',
  D38: '其他',
  D39: '生物科技',
  D40: '医疗器械',
  D41: '基因测序',
  D42: '实验平台',
  D43: '靶向治疗',
  D44: '遇见你的伯乐',
  D45: '天使投资人、业界大咖、TOP设计人、互联网英雄…寻找未来的自己',
  D46: '更多伯乐',
  D47: '账号或密码错误',
  D48: '请输入正确的邮箱'
}
let details = {
  goal: '大赛宗旨',
  g1: '汇聚全球极具竞争力的顶尖创意人才、创意设计作品和创新商业项目',
  g2: '为优秀创意作品、优良商业项目提供孵化基金和人才基金，吸引全球优秀创意和创新项目入驻中国长三角城市创意产业园区',
  g3: '为全球优品开通阿里天猫等线上平台渠道，实现设计成果商业转化',
  g4: '选拔中国优秀创意作品和优质企业产品参加国内国际展览，代表中国设计力量全球发声',
  g5: '',
  ThreeTHEMES: '大赛模块',
  ThreeTHEMES1: '本次大赛分为A：设计作品模块、B：设计创业模块和C：原创名品云上线模块共三大模块。',
  ThreeTHEMES2: '',
  ThreeTHEMES3: 'A：设计作品模块',
  ThreeTHEMES4: '参赛要求：',
  ThreeTHEMES5: '以设计作品参赛的赛事模块，分为自由探索的概念组和古堰画乡杯产业组。',
  ThreeTHEMES6: '主要包含文创设计、时尚设计、工业设计（含产品设计、包装设计、视觉设计等）、创新设计（含数字设计、新材料设计等新设计）四大板块的参赛方向。',
  ThreeTHEMES7: '城市印象文创类、食品、生活用品、化妆品、服装、珠宝行业应用优先。',
  ThreeTHEMES8: '特别推出“企业设计项目”，面向长三角城市、产业园区及企业的设计需求设置命题赛事，具体细节分类详见报名网站。',
  ThreeTHEMES9: 'B：设计创业模块',
  ThreeTHEMES10: '参赛要求：',
  ThreeTHEMES11: '以“创业商业计划书”参赛，并进行项目路演的赛事模块，主要面向项目孵化需求的创意设计、数字智能、医疗健康等相关项目。【参赛方向与行业不限。】',
  ThreeTHEMES12: '【优先支持】进驻南翔智地园区项目、数字智能项目、创意设计项目、医疗健康项目。',
  ThreeTHEMES13: 'C：原创作品模块',
  ThreeTHEMES14: '参赛要求：',
  ThreeTHEMES15: '以已上市作品、产品及品牌参赛，并进行作品汇报的赛事模块，主要面向品牌上线需求的设计作品与产品及品牌。【参赛方向与行业不限。】',
  ThreeTHEMES16: '【优先支持】原创名品项目、拟进驻天猫项目、拟拓展市场通路项目。',
  ThreeTHEMES17: '大赛细则',
  ThreeTHEMES18: '参赛对象',
  ThreeTHEMES19: '设计创意机构 ',
  ThreeTHEMES20: '设计创意产品企业',
  ThreeTHEMES21: '自由创意设计师',
  ThreeTHEMES22: '商业创意机构',
  ThreeTHEMES23: '创业项目创始人',
  ThreeTHEMES24: '有设计作品/产品的工商企业',
  ThreeTHEMES25: '潜在机会',
  ThreeTHEMES26: '优惠的园区入驻政策（B.C）',
  ThreeTHEMES27: '丰厚的项目孵化基金（B.C）',
  ThreeTHEMES28: '天猫等网络销售渠道直通车（B.C）',
  ThreeTHEMES29: '向全球展出作品的机会（A.B.C）',
  ThreeTHEMES30: '入驻长三角创意人才库的机遇（A.B.C）',
  ThreeTHEMES31: '参与世界创意峰会（A.B.C）',
  ThreeTHEMES32: '参赛方向',
  ThreeTHEMES33: '文创设计',
  ThreeTHEMES34: '时尚设计',
  ThreeTHEMES35: '工业设计',
  ThreeTHEMES36: '含产品设计、包装设计、视觉设计等',
  ThreeTHEMES37: '创新设计',
  ThreeTHEMES38: '含数字设计、新材料设计等',
  ThreeTHEMES39: '日程安排',
  ThreeTHEMES40: '征集和评审',
  ThreeTHEMES41: '初评',
  ThreeTHEMES42: '初评结果公布',
  ThreeTHEMES43: 'Demo提交',
  ThreeTHEMES44: '复评',
  ThreeTHEMES45: '颁奖典礼',
  ThreeTHEMES46: '2020年7月01日 - 2020年10月30日',
  ThreeTHEMES47: '2020年11月01日 - 2020年11月15日',
  ThreeTHEMES48: '2020年11月16日',
  ThreeTHEMES49: '2020年11月16日 - 2020年12月05日',
  ThreeTHEMES50: '2020年12月08日 - 2020年12月18日',
  ThreeTHEMES51: '2020年12月',
  ThreeTHEMES52: '奖项设置',
  ThreeTHEMES53: '设计作品模块',
  ThreeTHEMES54: '金奖（1个、50000元）',
  ThreeTHEMES55: '长三角“无畏”全球创意人才计划荣誉称号（作品入选长三角全球创意设计大赛“名品馆”，获得大赛上海总部孵化空间直通机会）',
  ThreeTHEMES56: '长三角全球创意设计大赛颁奖典礼主题演讲嘉宾资格机会（当地交通+食宿）',
  ThreeTHEMES57: '欧洲顶级设计周作品参展直选机会',
  ThreeTHEMES58: '长三角创意经济专委会国际合作交流团“大师”资格机会（代表“中国创意力量”作为“世界创意大会”主论坛发言嘉宾）',
  ThreeTHEMES59: '银奖（3个、20000元）',
  ThreeTHEMES60: '长三角“无畏”全球创意人才计划荣誉称号（作品入选长三角全球创意设计大赛“名品馆”，获得大赛上海总部孵化空间直通机会）',
  ThreeTHEMES61: '长三角全球创意设计大赛颁奖典礼平行论坛嘉宾（当地食宿）',
  ThreeTHEMES62: '欧洲顶级设计周作品参展直选机会',
  ThreeTHEMES63: '长三角创意经济专委会欧洲合作交流团“导师”资格机会（代表“中国创意力量”作为“世界创意大会”演讲嘉宾）',
  ThreeTHEMES64: '铜奖（5个、6000元）',
  ThreeTHEMES65: '长三角“无畏”全球创意人才计划荣誉称号（作品入选长三角全球创意设计大赛“名品馆”，获得大赛上海总部孵化空间直通机会）',
  ThreeTHEMES66: '长三角全球创意设计大赛颁奖典礼平行论坛嘉宾机会（当地餐食）',
  ThreeTHEMES67: '欧洲顶级设计周作品参展推荐机会+国际合作交流团“讲师”资格机会（代表“中国创意力量”作为“世界创意大会”交流嘉宾）',
  ThreeTHEMES68: '',
  // ThreeTHEMES69: '入围奖（10个、3000元）',
  ThreeTHEMES69: '入围奖（30个、1000元）',
  ThreeTHEMES70: '长三角“无畏”全球创意人才计划荣誉称号（作品入选长三角全球创意设计大赛“名品馆”，获得大赛上海总部孵化空间直通机会）',
  ThreeTHEMES71: '长三角全球创意设计大赛颁奖典礼平行论坛嘉宾机会（当地餐食）',
  ThreeTHEMES72: '欧洲顶级设计周作品参展推荐机会+国际合作交流团“讲师”资格机会（代表“中国创意力量”作为“世界创意大会”交流嘉宾）',
  ThreeTHEMES73: '',
  ThreeTHEMES74: '新人奖（100个）',
  ThreeTHEMES75: '三角“无畏”全球创意人才计划荣誉称号（作品入选长三角全球创意设计大赛“名品馆”，获得大赛上海总部孵化空间推选机会）',
  ThreeTHEMES76: '长三角全球创意设计大赛颁奖典礼活动交流机会（当地餐食）',
  ThreeTHEMES77: '欧洲顶级设计周个人参展邀约机会+长三角创意经济专委会国际合作交流团“团员”资格机会（代表“中国创意力量”作为“世界创意大会”观摩嘉宾）。',
  ThreeTHEMES78: '* 如果上述设计获奖作品以单位名义申报，上述长三角“无畏”全球创意人才计划奖项对应为长三角“无畏”全球创意创业联盟；其他活动类奖项标准按照1人计算。上海南翔孵化空间入驻机会，优惠政策解读见附件。',
  ThreeTHEMES79: '设计创业模块',
  ThreeTHEMES80: '金奖（1个）',
  ThreeTHEMES81: '长三角无畏全球创意创业联盟“联合理事长”资格机会（可直通入驻大赛上海南翔智地孵化空间，推荐获得百万级孵化资金）',
  ThreeTHEMES82: '长三角全球创意设计大赛颁奖典礼主题演讲嘉宾资格机会（当地交通+食宿）',
  ThreeTHEMES83: '2021年度世界创意论坛CWF（国际活动门票+期间餐食）',
  ThreeTHEMES84: 'DC Living Lab（国际活动门票+国际期间食宿）',
  ThreeTHEMES85: '全球（加拿大）顶级创意大会C2峰会直通机会',
  ThreeTHEMES86: '长三角创意经济专委会国际合作交流团“大师”资格机会（代表“中国创意力量”作为“世界创意大会”发声嘉宾）',
  ThreeTHEMES87: '银奖（6个）',
  ThreeTHEMES88: '长三角无畏全球创意创业联盟“联合副理事长”资格机会（可直通入驻大赛上海南翔智地孵化空间，推荐获得伍拾万级孵化资金）',
  ThreeTHEMES89: '长三角全球创意设计大赛颁奖典礼主题演讲嘉宾资格机会（当地交通+餐食）',
  ThreeTHEMES90: '  2021年度世界创意论坛CWF（国际活动门票+期间餐食）',
  ThreeTHEMES91: 'DC Living Lab推选机会（国际活动门票+国际期间食宿）',
  ThreeTHEMES92: '全球（加拿大）顶级创意大会C2峰会直通机会',
  ThreeTHEMES93: '长三角创意经济专委会国际合作交流团“导师”资格机会（代表“中国创意力量”作为“世界创意大会”演讲嘉宾）',
  ThreeTHEMES94: '铜奖（30个）',
  ThreeTHEMES95: '长三角无畏全球创意创业联盟“理事”资格机会（可直通入驻大赛上海南翔智地孵化空间，推荐获得贰拾万级孵化资金）',
  ThreeTHEMES96: '长三角全球创意设计大赛颁奖典礼主题演讲嘉宾资格机会（当地餐食)',
  ThreeTHEMES97: '2021年度世界创意论坛CWF（国际活动门票+期间餐食）',
  ThreeTHEMES98: '全球（加拿大）顶级创意大会C2峰会直通机会',
  ThreeTHEMES99: '长三角创意经济专委会国际合作交流团“讲师”资格机会（代表“中国创意力量”作为“世界创意大会”交流嘉宾）。',
  Three1: '优秀奖（66人）',
  Three2: '长三角无畏全球创意创业联盟“理事”资格机会（可直通入驻大赛上海南翔智地孵化空间）',
  Three3: '长三角全球创意设计大赛颁奖典礼主题演讲嘉宾资格机会（当地餐食）',
  Three4: '2021年度世界创意论坛CWF（国际活动门票+期间餐食）',
  Three5: '全球（加拿大）顶级创意大会C2峰会直通机会',
  Three6: '长三角创意经济专委会国际合作交流团“讲师”资格机会（代表“中国创意力量”作为“世界创意大会”交流嘉宾）。',
  Three7: '原创名品模块',
  Three8: '* 以上奖项解释权归“长三角全球创意设计大赛”发起单位长三角创意经济专委会所有。',
  Three9: '金奖（5个）',
  Three10: '长三角全球创意设计大赛活动专题采访报道机会',
  Three11: '长三角无畏全球创意联盟“联合理事长”资格机会（可直通大赛天猫绿色通道；入选“锦绣江南”名品榜）',
  Three12: '长三角全球创意设计大赛颁奖典礼联合冠名宣传机会',
  Three13: '全球（巴黎）顶级设计周直通机会（代表“中国设计力量”全球发声）',
  Three14: '长三角创意经济专委会国际合作交流团“大师”资格机会（代表“中国创意力量”作为“世界创意大会”发声嘉宾）',
  Three15: '长三角全球创意设计大赛产教融合示范基地挂牌（可作为世界创意大会“DC Living Lab”中国区指定参观地）',
  Three16: '银奖（10个）',
  Three17: '推荐长三角全球创意设计大赛活动专题采访报道机会',
  Three18: '长三角无畏全球创意联盟“副理事长”资格机会（可直通大赛天猫绿色通道；入选“锦绣江南”名品榜）',
  Three19: '长三角全球创意设计大赛颁奖典礼推荐宣传机会',
  Three20: '全球（巴黎）顶级设计周直通机会（代表“中国设计力量”全球发声）',
  Three21: '长三角创意经济专委会国际合作交流团“导师”资格机会（代表“中国创意力量”作为“世界创意大会”发声嘉宾）',
  Three22: '长三角全球创意设计大赛产教融合示范基地挂牌（可作为世界创意大会“DC Living Lab”中国区指定参观地）',
  Three23: '铜奖（20个）',
  Three24: '推荐长三角全球创意设计大赛活动专题采访报道机会',
  Three25: '长三角无畏全球创意联盟“理事”资格机会（可直通大赛天猫绿色通道；入选“锦绣江南”名品榜）',
  Three26: '长三角全球创意设计大赛颁奖典礼邀约宣传机会',
  Three27: '全球（巴黎）顶级设计周推选机会（代表“中国设计力量”全球发声）',
  Three28: '长三角创意经济专委会国际合作交流团“讲师”资格机会（代表“中国创意力量”作为“世界创意大会”交流嘉宾）',
  Three29: '优秀奖（100个）',
  Three30: '长三角无畏全球创意联盟“会员”资格机会（可直通大赛天猫绿色通道；入选“锦绣江南”名品榜）',
  Three31: '长三角全球创意设计大赛活动专题采访报道报名机会',
  Three32: '长三角创意经济专委会国际合作交流团“团员”资格机会（代表“中国创意力量”作为“世界创意大会”观摩嘉宾）',
  Three33: '大赛简介',
  Three34: '长三角全球创意设计大赛旨在推动长三角更高质量一体化发展，驱动长三角区域创意发展与转型升级，打造“上海设计+长三角产业链”区域合作新模式。大赛由长三角区域合作办公室、长三角城市经济协调会办公室指导，长三角创意经济合作专业委员会（CECC）牵头，上海无畏创意经济区域合作促进中心和上海市创意产业协会联合承办，并协同比利时世界创意论坛（CWF）、英国格拉斯哥商会、法国巴黎创意谷、爱丁堡大学及其产业中心、西班牙Deusto大学创新孵化中心、丹麦科技大学SKYLAB、加拿大蒙特利尔顶尖数字创意企业、东华大学世界纺织高校教育联盟等国内外机构，进行全球发布并吸引全球优秀独立设计师、青年创客与国际创新创业学生及企业，以及长三角创意设计类高校师生共同参与，长三角城市经济协调会成员城市人民政府支持共同完成。',
  Three35: '',
  Three36: '',
  Three37: '报名时间与费用',
  Three38: '本次大奖所有作品的报名期限为2021年12月15日至2022年06月30日24时（北京时间），其他时间概不受理。',
  Three39: '组委会不收取报名费用，但由参赛所产生的物流、保险、清关等费用由参赛者自理。',
  Three40: '报名办法',
  Three41: '本届大赛采取网络报名方式，报名时间为2021年12月15日起至2022年06月30日止，参赛团队（模块A、模块B、模块C）都通过本网站进行报名。',
  Three42: '更多大赛内容和具体安排参见《2021全球创意设计大赛参赛手册》（开赛后将通过大赛官方渠道公布）。',
  Three43: '大赛重要通知和说明事项，由大赛组委会通过大赛官网、官方微信公众号和官方QQ群统一公告和通知。大赛官网 : https://yrd.cecc.sh.cn/#/brandActivity/IDC ',
  // Three44: '2021全球创意设计大赛奖项设置',
  Three44: '第二届全球创意设计大赛奖项设置'
}
let award = {
  A1: '请选择参赛模块',
  A2: '设计作品模块',
  A3: '设计创业模块',
  A4: '原创名品模块',
  A5: '请选择作品类型',
  A6: '文创设计',
  A7: '时尚设计',
  A8: '工业设计(含产品设计、包装设计、视觉设计等)',
  A9: '创新设计(含数字设计、新材料设计等)',
  A10: '请选择作品状态',
  A11: '仅概念',
  A12: 'DEMO/模型',
  A13: '已上市',
  A14: '*如选已上市，请选择是否需要',
  A15: '产业转化 ',
  A16: '线上推广 ',
  A17: '线下渠道 ',
  A18: '作品信息',
  A19: '作品名称',
  A20: '作品属性',
  A20_1: '请输入作品属性，例如家具，服装',
  A21: '保存信息',
  A22: '作品描述 ',
  A23: '作品定位',
  A24: '设计创新点 ',
  A25: '作品展示 ',
  A26: '主要封面图',
  A27: 'PNG/JPG格式，小于3M，1张，正方形（210mm*210mm），无文字，300dpi, RGB色彩。',
  A28: '详情效果图',
  A29: 'PNG/JPG格式，小于3M，4张以内，A4尺寸（210mm*297mm），横竖版不限, 300dpi, RGB色彩。  ',
  A30: '视频链接(可选填)',
  A31: '团队信息',
  A32: '作品所属方',
  A33: '作品设计方',
  A34: '主要参与人员',
  A35: '参与人员二',
  A36: '参与人员三',
  A37: '参与人员四',
  A38: '参与人员五',
  B1: '请选择创业项目类型 ',
  B2: '文化创意类',
  B3: '智能制造类',
  B4: '医疗健康类',
  B5: '其他',
  B6: '请选择项目状态',
  B7: '仅概念 ',
  B8: 'DEMO/模型',
  B9: '已上市 ',
  B10: '*如选已上市，请选择是否需要',
  B11: '产业转化 ',
  B12: '线上推广 ',
  B13: '线下渠道 ',
  B14: '品牌与设计赋能',
  B15: '技术与供应链渠道 ',
  B16: '商业基础信息 ',
  B17: '商业计划书名称(中/英)',
  B18: '商业计划说明 ',
  B19: '核心盈利点  ',
  B20: '商业计划书上传',
  B21: 'PDF格式，小于10M。',
  B22: '请选择企业类型（可多选）',
  B23: '工厂',
  B24: '品牌方',
  B25: '经销商',
  B26: '品牌基础信息',
  B27: '基础信息',
  B28: '品牌所属公司名称',
  B29: '品牌所属公司状态 ',
  B30: '品牌所属公司业务',
  B31: '品牌所属公司地址',
  B32: '请选择品牌产品类型',
  B33: '消费名品',
  B34: '文旅名品',
  B35: '农副产品',
  B36: '其他',
  B37: '品牌主打款',
  B38: '请选择主打品类',
  B39: '消费电子',
  B40: '服装服饰',
  B41: '大快消',
  B42: '文旅用品',
  B43: '服务',
  B44: '家装',
  B45: '其他',
  B46: '请选择产品需求（可多选）',
  B47: '产业转化',
  B48: '线上推广',
  B49: '线下渠道',
  B50: '品牌与设计赋能',
  B51: '技术与供应链渠道',
  B51_1: '海外市场拓展',
  B52: '请选择是否已有店铺',
  B53: '请选择产品线上年销售额',
  B54: '请选择产品线下年销售额',
  B55: '是',
  B56: '否',
  B57: '暂不参与',
  B58: '品牌上线信息（将生成品牌主页）',
  B59: '品牌名称',
  B60: '品牌简介',
  B61: '产品说明',
  B62: '产品卖点与优势',
  B63: '品牌Logo上传',
  B64: 'PNG/JPG格式，小于1M，1张，正方形（50mm*50mm），无文字，300dpi, RGB色彩。',
  B65: '品牌宣传图上传',
  B66: 'PNG/JPG格式，小于3M，3张，横版长方形（174mm*100mm），无文字，300dpi, RGB色彩。',
  B67: '视频链接(可选填) ',
  B68: '预览品牌主页',
  B69: '',
  B70: '',
}
let base = {
  H1: '精彩首页',
  H2: '关于我们',
  // H3: '服务指南',
  H3: '服务支持',
  H4: '品牌活动',
  H5: '联系方式',
  H6: '个人中心',
  H7: '0 - 50 人',
  H8: '50 - 200 人',
  H9: '200 - 500 人',
  H10: '500 - 1000 人',
  H11: '1000 人以上',
  H12: 'CWF创意峰会',
  H13: 'IDC设计大赛',
  H14: '锦绣江南名品榜',
  H15: '无畏中心',
  H16: "大师观点",
  H17: "创意资讯",
  H18: "创意游学",
  H19: '文创设计大咖',
  H20: 'Ramón Vila',
  H21: '上海迪士尼乐园的艺术总监',
  H22: '为所有迪士尼季节&节日',
  H23: '限定活动设计装饰',
  H24: '用经典又不失中国风的画笔',
  H25: '为上海迪士尼着新妆',
  H26: '将各国当地文化元素融合',
  H27: '全球语言文化展示',
  H28: '擅长品牌本土化并保证品牌形象',
  H29: '工业设计大咖',
  H30: '柳冠中',
  H31: '清华大学美术学院资深教授、',
  H32: '博士生导师',
  H33: '中国工业设计协会副理事长',
  H34: '兼学术和交流委员会主任',
  H35: '中国著名的工业设计',
  H36: '学术带头人和理论家',
  H37: '光华龙腾奖中国设计贡献奖金质奖章',
  H38: '被誉为“中国工业设计之父”',
  H39: '时尚设计大咖',
  H40: '李峻',
  H41: '东华大学上海国际时尚创意学院',
  H42: '院长、博士',
  H43: '服装设计与数字媒体艺术方向硕士生导师',
  H44: '东华-爱丁堡创意产业中心中方主任',
  H45: '上海世博会官方服装设计主创人员',
  H46: '第十一届上海市青联委员',
  H47: '国内外多家时尚品牌顾问',
  H48: '创新设计大咖',
  H49: 'Éloi Beauchamp',
  H50: '加拿大知名创意团队L’Éloi',
  H51: '的创始人',
  H52: '世界顶尖摄影大师',
  H53: '着重商业与艺术创意的融合',
  H54: '擅长在商业生态圈中建立原创作品',
  H55: 'L’Éloi 采用纯手工的方式',
  H56: '创作特效般的时尚大片',
  H57: '请粘贴品牌宣传视频链接',
  H58: '对于在作品征集及创意推广过程中做出突出贡献的机构和组织，还有机会获得：',
  H59: '最佳组织奖+奖金',
  H60: '中文，50字内，用一句话描述您的作品。',
  H61: '中文，300字内，原则上不要超过三个主要设计创新点。',
  H62: '请输入姓名',
  H63: '请输入担任职责',
  H64: '请选择所属方国籍',
  H65: '请选择所属方类型',
  H66: '个人',
  H67: '单位',
  H68: '请输入中文名称',
  H69: '请输入英文名称',
  H70: '中文，300字内。',
  H71: '中文，300字内，原则上不要超过三个核心盈利点。',
  H72: '请选择细分类型',
  H73: '请输入中文名称，10个字内。',
  H74: '中文，一句话概述，30字内。',
  H75: '中文，300字内。',
  H76: '中文，300字内，原则上不要超过三个主要卖点与优势。',
  H77: '1000万以下',
  H78: '1000-5000万',
  H79: '5000万-1亿',
  H80: '1亿-10亿',
  H82: '10亿以上',
  H83: '请输入公司中文地址',
  H84: '请输入作品中文名称',
  H85: '请输入作品英文名称',
  H86: '请输入作品中文属性，例如椅子等',
  H87: '请输入作品英文属性，例如椅子等',
  H88: '请输入公司主营商品',
  H89: '请选择公司年销售额',
  H90: '请选择商标状态',
  H91: '是否为纳税人',
  H92: '请输入品牌中文名称',
  H93: '请选择品牌所属公司成立时间',
  H94: '请输入品牌所属公司中文名称',
  H95: '请选择注册资本',
  H96: '链接所包含的视频时长在3分钟以内，建议以1280*720（720p），如有字幕至少应包含英文。',
  L1: '非遗文化',
  L2: '艺术展览',
  L3: '电子竞技',
  L4: '创意设计',
  L5: '其他',
  L6: '电子科技',
  L7: '智慧产品开发及应用',
  L8: '人工智能',
  L9: '智能材料',
  L10: '智慧汽车',
  L11: '无人机运用',
  L12: '环保节能产业',
  L13: '其他',
  L14: '生物科技',
  L15: '医疗器械',
  L16: '基因测序',
  L17: '实验平台',
  L18: '靶向治疗',
  L19: '其他',
  L20: '1990以前',
  L21: '1990-2000',
  L22: '2000-2010',
  L23: '2010-现在',
  moreDemands: '企业需求',
  L24: '您的信息已填报完整并成功保存',
  L25: '请输入完整信息',
  L26: '注册成功',
  L27: '登陆成功',
  L28: '出错了，请稍后再试',
  L29: '页面正在建设中',
  L30: '敬请期待',
  L31: '返回上一页',
  Q1: '我们是谁',
  Q2: 'ABOUT CECC',
  Q3: '长三角创意经济合作专业委员会 (creative economy cooperation committee，简称CECC）是由长江三角洲城市经济协调会第十六届市长联席会议批准设立的区域性专业类政府间合作机构，属于跨部门、跨地区和跨行业的社会服务组织，是协调会集聚文化创意经济资源和对外开展创意经济交流活动的主要载体。',
  Q4: '根据市长联席会议相关要求，在长三角城市经济协调会办公室直接领导下，长三角创意经济专业委员会（CECC）为协调会41个成员城市构建创意驱动型高质量发展模式提供专业化服务，推动形成“上海设计+长三角产业链”一体化的区域产业集群。同时，CECC也是世界创意经济区域合作国际组织的成员单位。',
  Q5: '',
  Q6: '',
  Q7: '组织架构：',
  Q8: '牵头单位为上海市文化创意产业推进领导小组办公室、上海市经信委，秘书长单位为东华大学。',
  // Q9: '服务范围：',
  Q9: '工作范围：',
  Q10: '1. 通过举办峰会大赛、编发杂志、新媒体传播等方式，构建国际性创意经济交流平台，展示长三角城市的国际形象；',
  Q11: '2. 筹备成立长三角创意经济研究中心智库，汇聚全球顶级创意人才，为长三角创意经济一体化发展提供决策咨询服务；',
  Q12: '3. 在全球范围内对接精品文创项目资源，推动成立创意孵化器产业基金，可为成员城市提供创意园区规划与代运营、文创产业综合体项目改造升级的专业化服务；',
  Q13: '4. 针对城市发展热点问题及传统产业转型中的难点，结合成员城市需求，不定期举办调研与座谈活动，掌握创意经济发展动态，并可组织相应的培训活动，邀约专家进行现场诊断；',
  Q14: '5. 加强与国际创意经济组织、国外创意城市的联络，探索创意经济国际合作新机制，协助长三角成员城市进行国际精准招商以及助推相关企业品牌“二次入世”向世界讲述中国故事。',
  Q15: '我们在哪',
  Q16: 'LOCATION',
  Q17: '上海，中国',
  Q18: 'CECC 地址：上海市长宁区延安西路1882号旭日楼',
  Q19: '无畏中心地址：上海市嘉定区沪宜公路1188号24号楼',
  Q20: '电话：021-62378609',
  Q21: '传真：021-62373399',
  Q22: '邮箱：cecc2016@126.com',
  Q23: '鲁汶，比利时',
  Q24: 'Districts of Creativity Network, c/o Flanders DC, Diestsevest 76',
  Q25: '邮箱：cecc2016@126.com',
  Q26: '',
  Q27: '我们是谁',
  Q28: 'ABOUT CECC',
  Q29: '长三角创意经济合作专业委员会 (creative economy cooperation committee,简称CECC)是由长江三角洲城市经济协调会第十六届市长联席会议批准设立的跨部门、跨地区和跨行业的区域性专业类政府间合作机构，是协调会集聚文化创意经济资源和对外开展创意经济交流活动的主要载体。在长三角协调会办公室的直接领导下开展工作，为长三角协调会41个会员城市推进创意经济发展提供专业化服务。CECC也是世界创意经济区域合作国际组织成员单位。',
  Q30: '请完成表单中未完成选项',
  Q31: '城市印象榜',
  Q32: '全球原创榜',
  Q33: '年度城市榜',
  Q34: '线上热销榜',
  Q35: '专家推荐榜',
  Q36: '社会热评榜',
  moreM: '作品管理',
  Q37: '新建成功',
  Q38: '提交信息',
  Q39: '提交后不可修改作品信息，是否确认提交?',
  Q40: '新增作品',
  Q41: '删除作品',
  Q42: '作品名称',
  Q43: '作品ID',
  Q44: '作品状态',
  Q45: '作品模块',
  Q46: '已提交',
  Q47: '草稿',
  Q48: '修改作品',
  Q49: '查看作品',
  Q50: '删除作品后不可恢复，是否确认删除?',
  Q51: '同步成功',
  Q52: '顾客产消对企业品牌价值共创影响研究',
  Q53: '近年来，价值共创理论在国内外学者的大力研究下得以快速发展并逐步走向完善。从生产领域分析，价值共创核心观点强调顾客知识的贡献或创造活动在研发新产品过程中的关键作用; 从消费领域分析，却又强调顾客作为“共同生产”的角色参与企业的研发过程，尤其是在服务业，顾客被视作兼职员工，在服务主导逻辑视角下涵盖了企业、顾客与其他相关者三方互动的价值共创分析框架。',
  Q54: '大师观点',
  Q55: '欧洲创新之旅（IAT）考察交流项目',
  Q56: '长三角创意经济合作专业委员会是本“欧洲创新之旅”的主办方，同时负责与安特卫普管理学院协调所有细节。安特卫普管理学院是本“欧洲创新之旅”的主要组织策划方，负责设计，开发，实施本项目，包括本项目所提出的所有课程，参观和各类活动等。所有各方须保持紧密联系和协同合作，做好一切必须的组织工作，包括例如签证办理，交通食宿安排等等细节，努力推动项目的顺利开展。',
  Q57: '创意游学',
  Q58: '往届峰会',
  Q59: '长三角创意资源对接会暨2019(第五届)上海世界创意经济峰会召开',
  Q60: '2019（第五届）上海世界创意经济峰会携手“上海设计之都活动周（以下简称‘上海设计周’）”，共襄盛会。2019上海世界创意经济峰会突出创意经济发展中IP、设计、数字体验的核心驱动要素功能，并通过全方位的沉浸式体验场景展示，传播全球设计新理念、新产品、新应用和新模式，将设计与文化、艺术、科技及商业灵动碰撞，从理念、行为及产品载体上立体实现“设计再出发”，共同打造设计梦想共同体。',
  Q61: '2018上海世界创意峰会主论坛召开——汇创意名企，秀创意名城',
  Q62: '2018年第四届上海世界创意经济峰会国际论坛以“IP与IT联动，创意与园区共生”为主题，邀约加拿大蒙特利尔作为主宾城市，以创意的无边渗透、国际交往、产业驱动为基础，引入国际性智力资源，搭建国际创意经济资源共享的平台，汇聚东西方创意经济的思想智慧，为创意城市、创意园区、创意产业发展提供指示器，促进全球创意经济发展战略的推进以及产业的对接。',
  Q63: '2017上海世界创意经济峰会开放论坛现场',
  Q64: '2017年第三届上海世界创意经济峰会国际论坛旨在以创意为引领，挖掘并活化传统文化资源，聚焦中国创意经济跨区域、跨界的协同发展，峰会论坛选择哥本哈根作为主宾城市，通过“互联创意·智慧生活”的主题，首次尝试在上海南翔和浙江松阳两地联合举办，向与会的社会各界展示CECC学界的理论影响能力、业界的资源整合能力和社会的智力服务能力，并获长三角协调会办公室领导批示为“亮点项目“。',
  Q65: '2016上海世界创意经济峰会国际论坛在嘉定南翔召开',
  Q66: '2016年第二届上海世界创意经济峰会国际论坛主题“互联创意 共享众筹”，邀约比利时布鲁日为主宾，东华大学和上海市嘉定区南翔镇为举办地；学术论坛参加人数为360人，峰会主论坛和三个分论坛参加人数规模超400人。  ',
  Q67: '获奖作品',
  moreGuests: '更多嘉宾',
  morePhotos: '更多照片',
  CWF: 'CWF',
  IDCT: 'IDC',
  aboutWuwei: 'ABOUT Wuwei',
  Wuwei: '上海无畏创意经济区域合作促进中心主要发起单位为长三角创意经济合作专业委员会，经上海市人民政府合作交流办公室批准，在上海市民政局正式注册成立的非营利性社会服务组织，作为长三角创意经济合作专业委员会对外开上海无畏创意经济区域合作促进中心主要发起单位为长三角创意经济合作专业委员会，经上海市人民政府合作交流办公室批准，在上海市民政局正式注册成立的非营利性社会服务组织，作为长三角创意经济合作专业委员会对外开上海无畏创意经济区域合作促进中心主要发起单位为长三角创意经济合作专业委员会，经上海市人民政府合作交流办公室批准，在上海市民政局正式注册成立的非营利性社会服务组织，作为长三角创意经济合作专业委员会对外开。'
}
export default {
  ...IDC,
  ...User,
  ...details,
  ...award,
  ...base
};