import { constructRequest } from './axios'

let api = {}

let RESTFullList = [
  'captcha_token',
  'captcha',
  'register',
  'login',
  'logout',
  'user_detail',
  'design_work',
  'design_product',
  'change_password',
  'admin_design_products',
  'admin_recommend',
  'reset_password',
  'reset_password_request',
  'articles',
  'admin_judge',
  'design_demand',
  'design_demand_list',
  'experts',
  'design_product_list',
  'selections',
  'design_demand_list_simple',
  'admin_article',
  'design_work_prize_list',
  'design_demand_detail',
  'expert_opinions',
  'article',
  'zones'
]

RESTFullList.forEach(item => {
  Object.assign(api, constructRequest(item));
});

export default api;